<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos Compuestos</h4>
            <div class="small text-muted">Administración de productos compuestos</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Producto Compuesto">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12" id="view_filters_products">    
          <b-card v-if="table.mostrarFiltros" id="list-products">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                <b-form-input type="text" 
                              placeholder="Ingrese un código o nombre de producto" 
                              v-model="filters.name"
                              size="sm">
                </b-form-input>                
              </b-col>  
              <b-col sm="3">
                <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
                <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              
              <b-col sm="3" v-if="hasLines">
                <v-select :options="arr.select.lines" v-model="filters.lines" placeholder="Lineas" :multiple="false" :select-on-tab="true" @input="filterLoadSubLines"></v-select>
                <v-select :options="arr.select.sublines" v-model="filters.sublines" placeholder="Sublineas" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>

              <b-col sm="2">
                <b-button variant="outline-dark" @click="load()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card header-tag="header" footer-tag="footer">      
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"   
                    :busy="table.isBusy"                    
                    v-if="table.items.length || table.isBusy">                       
                
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <b-row>
                    <b-col md="9">
                      <b-row>
                        <b-col md="12">
                          <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2 mb-2" v-b-tooltip.hover title="Agregar Composición">
                            <i class="fa fa-plus"></i> Agregar
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <b-table class="mb-0"
                                  responsive="sm"
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableSub.items"
                                  :fields="tableSub.fields"                            
                                  v-if="tableSub.items.length">   
                            
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template> 

                            <template v-slot:cell(id)="data">
                              <b>{{data.item.id}}</b>
                            </template>

                            <template v-slot:cell(code)="data">
                              {{data.item.product.code}}
                            </template>

                            <template v-slot:cell(products_id)="data">
                              {{data.item.product.name}}
                            </template>

                            <template v-slot:cell(quantity)="data">
                              {{data.item.quantity}}                        
                              <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                            </template>                            

                            <template v-slot:cell(price)="data">                              
                              <div v-if="data.item.product" :key="keyForceUpdate">
                                <div v-if="data.item.product.product_purchase">                                                                                                                                                               
                                  <span v-if="renderPriceProduct.length && renderPriceProduct[data.index]">                                     
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(renderPriceProduct[data.index].precio)}}
                                  </span>                                
                                </div>
                              </div>                            
                            </template>                              

                            <template v-slot:cell(total)="data">
                              <div v-if="data.item.product" :key="keyForceUpdate">
                                <div v-if="data.item.product.product_purchase">                                                                                                          
                                  <span v-if="renderPriceProduct.length && renderPriceProduct[data.index]">                                     
                                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(renderPriceProduct[data.index].total)}}
                                  </span>
                                </div>
                              </div>                            
                            </template>                              

                            <template v-slot:cell(f_action)="data">
                              <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                <b-dropdown-item @click="editSub(data.item)">
                                  <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeSub(data.item)">
                                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                </b-dropdown-item>
                              </b-dropdown>
                            </template>

                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <template v-slot:cell(id)="data">
                <b>{{data.item.id}}</b>
              </template>

              <template v-slot:cell(unit_measurement)="data">
                <b>{{data.item.unit_measurement.reference}}</b>: {{data.item.unit_measurement.name}} 
              </template>

              <template v-slot:cell(price_cost)="data">                                  
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(data.item.prices_cost)}}                                        
              </template>                      

              <template v-slot:cell(status)="data">                                  
                <div v-if="!data.item.details.length" title="Cargar la composición de este producto">
                  <b-icon icon="circle-fill" animation="throb" variant="danger" font-scale="2"></b-icon>
                </div>
                <div v-else>
                  <b-icon icon="circle-fill" variant="success" font-scale="1"></b-icon>
                </div>                  
              </template>

              <template v-slot:cell(prices)="data">                                
                <div v-if="data.item.prices.length<3">
                  <div v-for="element in data.item.prices" :key="element.id">
                    <div v-if="element.list">
                      <b :title="element.list.name" v-b-tooltip.hover>
                        <span v-if="element.list.reference">
                          {{element.list.reference.substring(0,5)}}
                        </span>
                        <span v-else>
                          {{element.list.name.substring(0,5)}}
                        </span>                              
                      </b> : 
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}} 
                      <span v-if="element.unit_measurement_result">{{element.unit_measurement_result.reference}}</span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <a href="javascript:void(0)" @click="setPricesProductsCompound(data.item)">Ver Listas</a>
                </div>
              </template>

              <template v-slot:cell(stock)="data">                                        
                <div v-if="data.item.stock>0">
                  <span class="text-success">{{data.item.stock}}</span>                        
                </div>
                <div v-else>
                  <span class="text-danger">{{data.item.stock}}</span>
                </div>
              </template>              

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                  <b-dropdown-item @click="setPricesProductsCompound(data.item)" v-if="modules.showPrices">
                    <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                  </b-dropdown-item>
                  <b-dropdown-item @click="setStockProductsCompound(data.item)" v-show="!parameters.hideStock && isProcessDeposit">
                    <i class="fa fa-cubes crud-product-action-icon crud-product-action-icon-stock"></i> Stock
                  </b-dropdown-item>                  

                  <b-dropdown-header>Acciones</b-dropdown-header>                         
                  <b-dropdown-item @click="edit(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="remove(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-card>
        </b-col>
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="load()" />            
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              size="lg"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">            
            <b-row>
              <b-col md="6" class="mb-4">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Activar (disponible en el sistema)
                </b-form-checkbox> 
              </b-col>                            
            </b-row>   

            <b-row>       
              <b-col md="4">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>                
              </b-col>
              <b-col md="8">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>                 
            </b-row>

            <b-row>   
              <b-col md="6">
                <b-row>   
                  <b-col md="12">                
                    <FindObject type="productsCategory" 
                                @select-object="loadProductsCategory($event)" 
                                :valueID="crud.form.category_id"
                                tag="Categorías"
                                render="search"/>
                  </b-col>
                  <b-col md="12">                
                    <FindObject type="productsSubCategory" 
                                @select-object="loadProductsSubcategory($event)" 
                                :valueID="crud.form.subcategory_id"
                                tag="Sub Categorias"
                                :where="conditionals.subcategorys"
                                :key="crud.form.category_id"
                                render="search"/>
                  </b-col>
                </b-row>
              </b-col>      
              <b-col md="6" v-if="hasLines">
                <b-row>   
                  <b-col md="12">                                
                    <FindObject type="productsLine" 
                                @select-object="loadProductsLine($event)" 
                                :valueID="crud.form.line_id"
                                tag="Lineas"
                                render="search"/>
                  </b-col>
                  <b-col md="12">                                
                    <FindObject type="productsSubLine" 
                                @select-object="loadProductsSubline($event)" 
                                :valueID="crud.form.subline_id"
                                tag="Sub Linea"
                                :where="conditionals.sublines"
                                :key="crud.form.line_id"
                                render="search"/>                    
                  </b-col>
                </b-row>
              </b-col>          
            </b-row>
          </b-tab>
          <b-tab no-body title="Configuración">
            <b-row>
              <b-col md="6">              
                <FindObject render="search"
                            type="unitMeasurement" 
                            @select-object="loadUnitMeasurement($event)" 
                            :valueID="crud.form.unit_measurement_id"/>
              </b-col>    
              <b-col>
                <b-form-group :label="'Adiccional (%)'">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.additional_percentage"
                                placeholder="Adiccionales">
                  </b-form-input>            
                </b-form-group>                
              </b-col>                                        
            </b-row>      
          </b-tab>
          <b-tab no-body title="Fiscal">
            <b-row>
              <b-col md="12">              
                <FindObject render="search"
                            tag="Condición iva"
                            type="erpIvaConditions" 
                            @select-object="loadIvaConditions($event)" 
                            :valueID="crud.form.iva_conditions_id"/>
              </b-col>              
            </b-row>            
          </b-tab>
          <b-tab no-body title="Contabilidad"  v-if="isProcessAccounting">
            <b-row>
              <b-col md="12">
                <FindObject render="search"
                            tag="Cuenta Contable"
                            type="accountingAccountsImputation" 
                            @select-object="loadAccountingAccounts($event)" 
                            :valueID="crud.form.accounting_accounts_id"
                            :where="conditionAccountingAccountsFilters"/>
              </b-col>
            </b-row>            
          </b-tab>                    
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>
          <b-col md="12">
            <FindObject render="search"
                        type="products"
                        @select-object="loadProducts($event)" 
                        :where="conditionProductsActive"
                        :valueID="crud.formSub.products_id"/>
          </b-col>            
          <b-col md="12" v-if="crud.formSub.unit_measurement">
            <b-form-group label="Cantidad">
              <b-input-group size="sm">
                <b-form-input type="number"
                              size="sm"
                              v-model="crud.formSub.quantity"
                              required>
                </b-form-input>

                <template #append>
                  <b-dropdown :text="crud.formSub.unit_measurement.reference" 
                              :title="crud.formSub.unit_measurement.name" 
                              variant="secondary"
                              v-if="Object.keys(arr.equivalences).length">
                    <b-dropdown-item v-for="element in arr.equivalences" 
                                    :key="element.id" 
                                    @click="setUnitMeasurementAlternative(element)">
                      {{element.name}} ({{element.reference}})
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button v-else size="sm" variant="secondary" :text="crud.formSub.unit_measurement.reference">
                    {{crud.formSub.unit_measurement.reference}} 
                  </b-button>
                </template>     
              </b-input-group>
            </b-form-group>
          </b-col>       
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-products-compound-title" v-if="itemSelected">
              <div class="crud-products-compound-title">
                <span v-if="itemSelected.code" class="crud-products-compound-code">
                  {{this.itemSelected.code}} 
                </span><br>
                {{this.itemSelected.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="5">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="crud.formPrice.price_list_select" 
                        placeholder="Lista"
                        :disabled="crud.formPrice.id>0">
              </v-select>
            </b-form-group>
          </b-col>    
          <b-col md="4">
            <b-form-group label="Importe">
              <b-form-input type="number"
                            v-model="crud.formPrice.amount"
                            step="0.01"
                            placeholder="Ingresar el precio">
              </b-form-input>
            </b-form-group>            
          </b-col>
          <b-col md="3">                
            <b-button variant="dark" 
                      @click="saveFormPrice()"
                      title="Guardar Precio"
                      class="crud-products-compound-button-save-price pull-right">
              <b-icon icon="plus" v-if="crud.formPrice.id==0"></b-icon>
              <i class="fa fa-save" v-else></i>
            </b-button> 
            <b-button variant="outline-danger" 
                      @click="cancelSaveFormPrice()"
                      title="Cancelar Edición"
                      class="crud-products-compound-button-save-price pull-right mr-1"                      
                      v-if="crud.formPrice.id>0">
              <i class="fa fa-close"></i>
            </b-button>                                  
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-products-compound-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">                
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-products-compound-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                
              
              <template v-slot:cell(price)="data">                
                <div v-if="data.item.list && data.item.list.currency">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                </div>
                <div v-else>
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.amount_total)}}                
                </div>                
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            text="Acción" 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPrice.id>0">
                  <b-dropdown-item @click="editPricesProductsCompound(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="removePricesProductsCompound(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este Servicio</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>    

      <!-- CRUD STOCK -->
      <b-modal  v-model="modal.formStock.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="md">
        <div slot="modal-header">
          {{this.modal.formStock.title}}
        </div>

        <b-row>       
          <b-col md="12">    
            <div class="crud-products-title" v-if="itemSelected">
              <div class="crud-products-title">
                <span v-if="itemSelected.code" class="crud-products-code">
                  {{this.itemSelected.code}} 
                  <br>
                </span>
                {{this.itemSelected.name}}
              </div>              
            </div>
          </b-col>

          <b-col md="12" v-if="isProcessDeposit">            
            <hr>
          </b-col>
          <b-col md="12" v-if="isProcessDeposit">            
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tableStock.items"
                    :fields="tableStock.fields"                            
                    v-if="tableStock.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>               

              <template v-slot:cell(quantity)="data">                            
                {{ parseFloat(data.item.quantity).toFixed(2) }} 
                <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
              </template>                 
            </b-table>
            <b-alert show v-else variant="warning">No se encontró stock</b-alert>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formStock.active=false">Cancelar</b-button>                 
        </div>

      </b-modal>          
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'  
  import Storage from '@/handler/storageSession'

  export default {
    components: {
      FindObject
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudProductsCompound',
          elements: {}
        },  
        parameters: {
          hideStock: Helper.hasParametersAccess(Param.P19),                
          haveLines: Helper.hasParametersAccess(Param.P7),          
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,
        },
        tableSub : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },   
        tablePrices : {
          items: [],
          fields: [
            {key: 'list', label: 'Lista', class:"align-middle", width:"250px"},
            {key: 'price', label: 'Precio', class:"align-middle", width:"100px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ]
        }, 
        tableStock : {
          items: [],
          fields: [
            {key: 'deposit', label: 'Deposito', class:"align-middle", width:"250px"},
            {key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"},            
          ]
        },                       
        crud: {
          form: {
            id: 0,
            code: '',
            name: '',
            accounting_accounts: null,
            accounting_accounts_id: 0,
            iva_conditions: null,
            iva_conditions_id: 0,
            unit_measurement: null,
            unit_measurement_id: 0,
            line: null,
            line_id: 0,            
            subline: null,
            subline_id: 0,
            category: null,
            category_id: 0,            
            subcategory: null,
            subcategory_id: 0,
            additional_percentage: 0,            
            active: true,
          }, 
          formSub: {
            id: 0,
            products_id: 0,
            products: null,
            products_compound_id: 0,
            quantity: 0,
            unit_measurement: null,
            unit_measurement_id: 0,
          },  
          formPrice: {
            id: 0,
            products_compound_id: 0,
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0
          },    
          formStock: {
            id: 0,
            products_compound_id: 0,
            stock: 0,            
            unit_measurement: null,
            unit_measurement_id: 0,
            detail: [],
          },                                      
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          }, 
          formPrice: {
            active: false,
            title: ''
          },    
          formStock: {
            active: false,
            title: ''
          },                              
        },
        arr: {
          detail: [],    
          depositeZone: [],      
          equivalences: [],
          select: {
            listPrice: [],
            lines: [],
            sublines:[],
            categorys: [],
            subcategorys:[],
            active:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ],            
          }              
        }, 
        filters: {
          lines: null,
          sublines: null,
          categorys: null,
          subcategorys: null,
          name: '',
          active: {code:true, label: 'Activo'},
        },        
        modulesActive: [],   
        modules: {
          showPrices: false
        },         
        renderPriceProduct: [],
        keyForceUpdate: 0,
        itemSelected: null,
        conditionals: {
          subcategorys: [
            {field: 'category_id', condition: 0}
          ],
          lines: [
            {field: 'line_id', condition: 0}
          ]          
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.configAccess()

      this.loadFieldTable()    
      this.filterLoad()        
      this.loadListPrice()
      this.loadZone()

      this.load()
    },
    computed: {
      isProcessSales() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.VENTAS || element.id == Modules.PRESUPUESTOS) {
            status = true
          }
        })
        return status
      },          
      isProcessAccounting() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {
            status = true
          }
        })
        return status
      },

      getCurrency() {
        return 'ARS'
      },

      isProcessDeposit() {
        var status = false
        this.modulesActive.forEach(element => {
          if(element.id == Modules.DEPOSITOS) {
            status = true
          }
        })
        return status
      },

      hasLines() {
        return this.parameters.haveLines
      },      

      // CONDITIONS SELECT
      conditionProductsActive(){
        return [{field: 'active', condition: true}]
      },   
      conditionAccountingAccountsFilters(){
        return [
          {field: 'active', condition: true},
          {field: 'type', condition: 'Ingresos'}
        ];
      },        
    },
    methods: {
      loadFieldTable() {
        // TABLE
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:" align-middle", width:"5%"})
        this.table.fields.push({key: 'status', label: 'Estado', class:"text-center align-middle", width:"10%"})
        this.table.fields.push({key: 'code', label: 'Código', class:" align-middle text-truncate", width:"5%"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:" align-middle text-truncate", width:"25%"})
        this.table.fields.push({key: 'unit_measurement', label: 'Unidad de Medida', class:" align-middle text-truncate text-center", width:"15%"})
        this.table.fields.push({key: 'price_cost', label: 'P.Costo', class:"text-right align-middle", width:"10%"})
        
        if(this.modules.showPrices) {
          this.table.fields.push({key: 'prices', label: 'P.Venta', class:"text-right align-middle", width:"15%"})
        }

        if(!this.parameters.hideStock && this.isProcessDeposit){
          this.table.fields.push({key: 'stock', label: 'Stock', class:"text-center align-middle", width:"10%"})
        }
        
        this.table.fields.push({key: 'active', label: 'Activo', class:"text-center align-middle", width:"15%"})
        this.table.fields.push({key: 'f_action', label:'', class:" align-middle", width:"10%"})

        // TABLE SUB
        this.tableSub.fields.push({key: 'id', label: 'ID', sortable: true, class:" align-middle", width:"50px"})
        this.tableSub.fields.push({key: 'code', label: 'Código', class:" align-middle", width:"80px"})
        this.tableSub.fields.push({key: 'products_id', label: 'Producto', class:" align-middle text-truncate", width:"250px"})
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"text-right align-middle", width:"100px"})
        this.tableSub.fields.push({key: 'price', label: 'P.Costo', class:"text-right align-middle"})
        this.tableSub.fields.push({key: 'total', label: 'Total', class:"text-right align-middle"})
        this.tableSub.fields.push({key: 'f_action', label:'', class:" align-middle", width:"100px"})
      },
      configAccess() {
        this.modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            this.modules.showPrices = true                             
          }
        })
      },      
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {  
        this.arr.detail.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)              
            }
          }
        })        
        if(item[0]) {
          this.getPrice(item[0].details)
        }
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       

      getPrice(details){        
        if(details) {
          details.forEach((element, indexProd) => {                
            if(element.product)     {              
              if(element.product.product_purchase) {                
                this.setUnitMeasurementAlternativeRecalculePrice(element,
                                                                 indexProd)                
              }
            }      
          });
        }
      },   

      filterLoad() {
        this.filterLoadLines()
        this.filterLoadCategorys()

        this.restoreFilterStorage() 
      },
      filterLoadLines() {
        var result = serviceAPI.obtenerLinea()        
        result.then((response) => {
          var data = response.data    
          
          data.forEach(element => {      
            this.arr.select.lines.push({code: element.id, label: element.name})                                                            
          });                                
        })   
      },
      filterLoadSubLines(val) {        
        this.arr.select.sublines=[]
        if(val) {
          var result = serviceAPI.obtenerLinea()        
          result.then((response) => {
            var data = response.data    
            
            data.forEach(element => {         
              if(element.id == val.code) {
                element.sublines.forEach(element1 => {
                  this.arr.select.sublines.push({code: element1.id, label: element1.name})                        
                }); 
              }
            });                                
          })   
        }
      },
      filterLoadCategorys() {
        var result = serviceAPI.obtenerCategoria()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {              
            this.arr.select.categorys.push({code: element.id, label: element.name})
          });                                
        })   
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        if(val) {          
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {          
              if(element.id == val.code) {
                element.subcategorys.forEach(element1 => {                
                  this.arr.select.subcategorys.push({code: element1.id, label: element1.name})
                }); 
              }                                  
            });                                
          })   
        }
      },
      load(forceOpenPricesCourses=false) {
        this.saveFilterStorage()

        this.table.isBusy = true
        var result = serviceAPI.filtrarProductoCompuesto(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.detail = data.data

          this.table.items.forEach(element => {            
            if(forceOpenPricesCourses) {
              if(element.id == this.itemSelected.id) {
                this.setPricesProductsCompound(element)
              }              
            }              
          }); 

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true 

            var item = this.table.items[this.table.rowSelected]            
            if(item) {
              this.getPrice(item.details)
            }                  
            
            if(this.table.items[this.table.rowSelected].details) {
              this.tableSub.items = this.table.items[this.table.rowSelected].details
            }
          }  

          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.code = '' 
        this.crud.form.name = '' 
        this.crud.form.accounting_accounts = null
        this.crud.form.accounting_accounts_id = 0
        this.crud.form.iva_conditions = null
        this.crud.form.iva_conditions_id = 0
        this.crud.form.unit_measurement = null
        this.crud.form.unit_measurement_id = 0
        this.crud.form.additional_percentage = 0
        this.crud.form.line = null
        this.crud.form.line_id = 0          
        this.crud.form.subline = null
        this.crud.form.subline_id = 0
        this.crud.form.category = null
        this.crud.form.category_id = 0          
        this.crud.form.subcategory = null
        this.crud.form.subcategory_id = 0        
        this.crud.form.active = true

        this.modal.form.title = "Nuevo Producto Compuesto"
        this.modal.form.active = true
      },
      edit(item) {
        let loader = this.$loading.show();

        this.crud.form.id = item.id
        this.crud.form.code = item.code
        this.crud.form.name = item.name
        this.crud.form.accounting_accounts = item.accounting_accounts
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id
        this.crud.form.iva_conditions = item.iva_conditions
        this.crud.form.iva_conditions_id = item.iva_conditions_id
        this.crud.form.unit_measurement = item.unit_measurement
        this.crud.form.unit_measurement_id = item.unit_measurement_id
        this.crud.form.additional_percentage = item.additional_percentage        
        this.crud.form.active = item.active

        if(item.subline) {
          this.crud.form.line_id = item.subline.line_id
          this.setConditionalSubLine(item.subline.line_id)          
          this.crud.form.subline_id =  item.subline_id        
        } else {
          this.crud.form.line_id = 0
          this.setConditionalSubLine(0)
          this.crud.form.subline_id = 0            
        }
        
        if(item.subcategory) {
          this.crud.form.category_id = item.subcategory.category_id
          this.setConditionalSubCategory(item.subcategory.category_id)
          this.crud.form.subcategory_id = item.subcategory_id
        } else {
          this.crud.form.category_id = 0
          this.setConditionalSubCategory(0)
          this.crud.form.subcategory_id = 0          
        }

        this.modal.form.title = "Editar Producto Compuesto"
        this.modal.form.active = true

        loader.hide()
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Producto Compuesto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProductoCompuesto(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarProductoCompuesto(this.crud.form);
        } else {
          var result = serviceAPI.agregarProductoCompuesto(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.products_compound_id = item.id
        this.crud.formSub.products_id = 0
        this.crud.formSub.quantity = 0
        this.crud.formSub.unit_measurement = null
        this.crud.formSub.unit_measurement_id = 0

        this.modal.formSub.title = "Nueva Composición"
        this.modal.formSub.active = true
      },
      editSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.products_compound_id = item.products_compound_id
        this.crud.formSub.products_id = item.products_id
        this.crud.formSub.quantity = item.quantity
        this.crud.formSub.unit_measurement = item.unit_measurement
        this.crud.formSub.unit_measurement_id = item.unit_measurement_id

        this.modal.formSub.title = "Editar Composición"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.product = item.product

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.formSub.id + ') - '+ this.crud.formSub.product.name + '?', {
          title: 'Borrar Composición',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProductoCompuestoDetalle(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        let loader = this.$loading.show();
        
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarProductoCompuestoDetalle(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarProductoCompuestoDetalle(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.keyForceUpdate = this.keyForceUpdate + 1
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadProducts (object) {
        if(object){
          this.crud.formSub.products = object
          this.crud.formSub.products_id = object.id

          if(!this.crud.formSub.unit_measurement) {
            this.crud.formSub.unit_measurement = object.unit_measurement_sale
            this.crud.formSub.unit_measurement_id = object.unit_measurement_sale_id   
          }
          
          this.loadEquivalences()  
        } else {
          this.crud.formSub.products = null
          this.crud.formSub.products_id = 0

          this.crud.formSub.unit_measurement = null
          this.crud.formSub.unit_measurement_id = 0
        }
      },    
      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },
      loadIvaConditions (object) {
        if(object){
          this.crud.form.iva_conditions = object
          this.crud.form.iva_conditions_id = object.id             
        } else {
          this.crud.form.iva_conditions = null
          this.crud.form.iva_conditions_id = 0
        }
      },          
      loadUnitMeasurement(object) {
        if(object){
          this.crud.form.unit_measurement = object
          this.crud.form.unit_measurement_id = object.id             
        } else {
          this.crud.form.unit_measurement = null
          this.crud.form.unit_measurement_id = 0
        }        
      },


      loadProductsLine (object) {
        if(object){
          this.crud.form.line = object
          this.crud.form.line_id = object.id
          this.crud.form.subline = null
          this.crud.form.subline_id = 0

          this.setConditionalSubLine(object.id)
        } else {
          this.crud.form.line = null
          this.crud.form.line_id = 0
          this.crud.form.subline = null
          this.crud.form.subline_id = 0

          this.setConditionalSubLine(object.id)
        }
      },  
      loadProductsSubline (object) {
        if(object){
          this.crud.form.subline = object
          this.crud.form.subline_id = object.id
        } else {
          this.crud.form.subline = null
          this.crud.form.subline_id = 0
        }
      },  
      loadProductsCategory (object) {
        if(object){
          this.crud.form.category = object
          this.crud.form.category_id = object.id    
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0

          this.setConditionalSubCategory(object.id)
        } else {
          this.crud.form.category = null
          this.crud.form.category_id = 0
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0          

          this.setConditionalSubCategory(0)
        }
      },               
      loadProductsSubcategory (object) {
        if(object){
          this.crud.form.subcategory = object
          this.crud.form.subcategory_id = object.id          
        } else {
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0          
        }
      },  
      
      setConditionalSubLine(id) {
        this.conditionals.sublines = [
          {field: 'line_id', condition: id},
        ]      
      },      
      setConditionalSubCategory(id) {
        this.conditionals.subcategorys = [
          {field: 'category_id', condition: id},
        ]      
      },            

      loadListPrice() {
        var result = serviceAPI.obtenerListaPrecios()

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {
            this.arr.select.listPrice.push({
              code: element.id,
              label: element.name
            })  
          });    
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },      
      setPricesProductsCompound(item) {
        this.crud.formPrice.id = 0
        this.crud.formPrice.products_compound_id = item.id
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0

        this.tablePrices.items = item.prices
        this.itemSelected = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Mantenimiento de Precios"
      },
      editPricesProductsCompound(item) {
        this.crud.formPrice.id = item.id
        this.crud.formPrice.products_compound_id = item.products_compound_id
        this.crud.formPrice.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPrice.price_list_id = item.list.id
        this.crud.formPrice.amount = item.amount_total      
        this.crud.formPrice.amount_old = item.amount_total
      },    
      cancelSaveFormPrice(){
        this.crud.formPrice.id = 0
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
      },
      removePricesProductsCompound(item) {
        var id = item.id
        var listName = 'LISTA: ' + item.list.name        
        var listAmount = ''
        
        if(item.list.currency)
          listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:item.list.currency.code}).format(item.amount_total)
        else
          listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(item.amount_total)

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item de la ' + listName + ' con ' + listAmount + '?', {
          title: 'Borrar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.eliminarPrecioProductoCompuesto(id);

            result.then((response) => {      
              loader.hide()
              this.load(true)
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveFormPrice(){
        let loader = this.$loading.show();

        if(this.crud.formPrice.price_list_select) {
          this.crud.formPrice.price_list_id = this.crud.formPrice.price_list_select.code
        }        

        if (this.crud.formPrice.id) {
          var result = serviceAPI.editarPrecioProductoCompuesto(this.crud.formPrice)
        } else {
          var result = serviceAPI.agregarPrecioProductoCompuesto(this.crud.formPrice)
        }

        result.then((response) => {                         
          loader.hide()
          this.load(true)          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },   

      // LOAD EQUIVALENCES
      loadEquivalences() {        
        this.arr.equivalences = []

        if(this.crud.formSub.products) {        
          if(this.crud.formSub.products.unit_measurement_purchase_default) {            
            var result = serviceAPI.obtenerEquivalencesAvailable({
              unit_measurement_id: this.crud.formSub.products.unit_measurement_purchase_default.id,
            })

            result.then((response) => {
              var data = response.data
              this.arr.equivalences = data
            })      
          }
        }
      },
      setUnitMeasurementAlternative(element) {                
        this.crud.formSub.unit_measurement = element
        this.crud.formSub.unit_measurement_id = element.id                   
      },
      async setUnitMeasurementAlternativeRecalculePrice(item, indexProd) {                                      
        var result = await serviceAPI.recalculePriceByUnitMeasurementAlternative({
          products_id: item.product.id,
          price: parseFloat(item.product.product_purchase.price_cost),
          uni: item.product.unit_measurement_purchase_default.id,
          unr: item.unit_measurement.id,
        })        
        this.renderPriceProduct[indexProd] = {
          precio: parseFloat(result.data),
          total: parseFloat(result.data) * parseFloat(item.quantity),
        }

        this.keyForceUpdate = this.keyForceUpdate + 1
      } ,
      
      // STOCK
      setStockProductsCompound(item) {            
        this.crud.formStock.id = item.id
        
        this.crud.formStock.products_compound_id = item.id
        this.crud.formStock.stock = item.stock

        this.crud.formStock.unit_measurement = item.unit_measurement
        this.crud.formStock.unit_measurement_id = item.unit_measurement.id

        this.crud.formStock.detail = []
        if(item.depositLocationDetail) {
          item.depositLocationDetail.forEach(element => {
            this.crud.formStock.detail.push({
              'deposit': this.getNameDeposit(element),
              'quantity': element.quantity,
              'unit_measurement': this.crud.formStock.unit_measurement,
            })            
          });
        }
        this.tableStock.items = this.crud.formStock.detail
    
        this.itemSelected = item

        this.modal.formStock.active = true

        if(this.isProcessDeposit) {
          this.modal.formStock.title = "Stock"  
        } else {
          this.modal.formStock.title = "Mantenimiento de Stock"
        }        
      },
      getNameDeposit(item) {        
        var name = ''
        if(this.arr.depositeZone) {
          this.arr.depositeZone.forEach(element => {            
            if(item.location) {              
              if(element.id == item.location.zone_id) {              
                name = item.location.zone.deposit.reference + ' - ' + item.location.zone.reference + ' - ' + item.location.reference               
              }
            }
          });
        }
        return name
      }, 
      loadZone() {        
        this.arr.zone = []
        var result = serviceAPI.obtenerDepositoZona()

        result.then((response) => {
          var data = response.data
          this.arr.depositeZone = data           
        })
      }, 
      
      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_products_compound', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_products_compound')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_products_compound'))
        }         
      },       
    }    
  }
</script>
<style>
  .crud-product-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-table-products-compound-code {
    font-size: 12px;
    font-weight: bold;  
  }
  .crud-products-compound-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-products-compound-code {
    color: gray;
  }    
  .crud-products-compound-button-save-price {
    margin-top: 30px;
  }    
  .crud-products-compound-list-prices-public {
    font-size: 10px;
    font-weight: 600;
    color: gray;
  }
  .crud-products-compound-table-prices {
    min-height: 100px;
  }   
  .crud-product-action-icon-stock {
    color: #000 !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }    
</style>